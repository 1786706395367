<template>
    <AppIcon
        v-bind="$props"
        :class="[ $style['chevron-down'], { [$style['chevron-down--rotate']]: $attrs['rotate'] } ]"
    >
        <template #default="{ color }">
            <path
                d="M7.41 8.57996L12 13.17L16.59 8.57996L18 9.99996L12 16L6 9.99996L7.41 8.57996Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>

<style
    lang="sass"
    module
    scoped
>
    .chevron-down
        transition: transform 100ms var(--transition-default-ease)
        will-change: transform

        &--rotate
            transform: rotate(0.5turn)
</style>
